import { State, Action, StateContext, Selector, SelectorOptions } from '@ngxs/store';
import { VehicleRegistrationModel } from '../models/vehicle-registration';
import { VehicleRegistrationActions } from '../actions/vehicle-registration.actions';
import {VehicleRegistrationService} from '../services/vehicle-registration.service';
import {map, tap, catchError} from 'rxjs/operators';
import {forwardRef, Inject, Injectable} from '@angular/core';
import {patch, updateItem, removeItem} from '@ngxs/store/operators';
import { throwError, of } from 'rxjs';
import { prepareEventListenerParameters } from '@angular/compiler/src/render3/view/template';
import { ActivityModel } from '../models/activity-model';
import { SeasonState } from './season-state';
import { SeasonModel } from '../models/season-model';

const flattened = arr => [].concat(...arr);

export class VehicleRegistrationStateModel {
    vehicleRegistrations: VehicleRegistrationModel[];
    selectedVehicleRegistrations: VehicleRegistrationModel[];
}

@State<VehicleRegistrationStateModel>({
    name: 'vehicleRegistrations',
    defaults: {
        vehicleRegistrations: [],
        selectedVehicleRegistrations: []
    }
})
@Injectable()
export class VehicleRegistrationState {

    constructor(private vehicleRegistrationService: VehicleRegistrationService) { }

    @Selector()
    static getVehicleRegistrationList(state: VehicleRegistrationStateModel) {
        return state.vehicleRegistrations.sort((a: VehicleRegistrationModel, b: VehicleRegistrationModel) => new Date(b.modified).getTime() - new Date(a.modified).getTime());
    }

    @Selector()
    static activitiesByApproved(state: VehicleRegistrationStateModel) {
        const approved = VehicleRegistrationState.approved(state)
        const allActivities = flattened(approved.map(vr => vr.activities))
        const uniqueActivitiesMap = allActivities.reduce((prev, curr) => ({[curr.id]: curr, ...prev}), {})
        const uniqueActivities = Object.values(uniqueActivitiesMap)
        return uniqueActivities
    }

    @Selector()
    static approved(state: VehicleRegistrationStateModel) {
        return state.vehicleRegistrations.filter(vr => vr.state === 'A').sort((a: VehicleRegistrationModel, b: VehicleRegistrationModel) => new Date(b.modified).getTime() - new Date(a.modified).getTime());
    }

    @Selector()
    static denied(state: VehicleRegistrationStateModel) {
        return state.vehicleRegistrations.filter(vr => vr.state === 'DE').sort((a: VehicleRegistrationModel, b: VehicleRegistrationModel) => new Date(b.modified).getTime() - new Date(a.modified).getTime());
    }

    @Selector()
    static partlyFilled(state: VehicleRegistrationStateModel) {
        return state.vehicleRegistrations.filter(vr => vr.state === 'PF').sort((a: VehicleRegistrationModel, b: VehicleRegistrationModel) => new Date(b.modified).getTime() - new Date(a.modified).getTime());
    }

    @Selector()
    static sent(state: VehicleRegistrationStateModel) {
        return state.vehicleRegistrations.filter(vr => vr.state === 'S').sort((a: VehicleRegistrationModel, b: VehicleRegistrationModel) => new Date(b.modified).getTime() - new Date(a.modified).getTime());
    }

    @Selector()
    static paid(state: VehicleRegistrationStateModel) {
        return state.vehicleRegistrations.filter(vr => vr.state === 'P').sort((a: VehicleRegistrationModel, b: VehicleRegistrationModel) => new Date(b.modified).getTime() - new Date(a.modified).getTime());
    }

    @Selector()
    static byId(state: VehicleRegistrationStateModel) {
        return (id: string) => state.vehicleRegistrations.find(vr => vr.id === id);
    }

    @Selector()
    static selectedList(state: VehicleRegistrationStateModel) {
        return state.selectedVehicleRegistrations
    }

    @Action(VehicleRegistrationActions.Add)
    add({getState, patchState}: StateContext<VehicleRegistrationStateModel>, {payload}) {
        console.log('add stuff heree')
        const state = getState();
        patchState({
            vehicleRegistrations: [payload, ...state.vehicleRegistrations]
        });
    }

    @Action(VehicleRegistrationActions.FetchAll)
    fetchAll({ getState, setState }: StateContext<VehicleRegistrationStateModel>) {
        return this.vehicleRegistrationService.vehicleRegistrations().pipe(
            map(listResponse => listResponse.results),
            map(vehicleRegistrations => vehicleRegistrations.map(vr => {
                if (vr.vehicle && vr.vehicle.manufacturer && vr.vehicle.typeDesignation) {
                    vr.vehicleName = `${vr.vehicle.manufacturer.name} ${vr.vehicle.typeDesignation.name} (${vr.activities.map(a => a.name).join(',')})`
                }
                return vr
            })),
            tap(vehicleRegistrations => {
                const state = getState();
                setState({
                    ...state,
                    vehicleRegistrations
                });
            })
        );
    }

    @Action(VehicleRegistrationActions.Update)
    update(ctx: StateContext<VehicleRegistrationStateModel>, {payload}) {
        const state = ctx.getState()
        if (state.vehicleRegistrations.find(vr => vr.id === payload.id)) {
            ctx.setState(patch({
                vehicleRegistrations: updateItem<VehicleRegistrationModel>(vr => vr.id === payload.id, patch(payload))
            }));
        } else {
            ctx.patchState({
                vehicleRegistrations: [payload, ...state.vehicleRegistrations]
            });
        }
        
    }

    @Action(VehicleRegistrationActions.SelectList)
    selectList(ctx: StateContext<VehicleRegistrationStateModel>, {payload}) {
        ctx.patchState({
            selectedVehicleRegistrations: payload
        })
    }

    @Action(VehicleRegistrationActions.Remove)
    remove(ctx: StateContext<VehicleRegistrationStateModel>, {payload}) {
        ctx.setState(patch({
            vehicleRegistrations: removeItem<VehicleRegistrationModel>(vr => vr.id === payload.id)
        }))
        return this.vehicleRegistrationService.removeVehicleRegistration(payload.id).pipe(
            catchError((error) => {
                ctx.dispatch(new VehicleRegistrationActions.Add(payload));
                return throwError(error)
            })
        )
    }
}
