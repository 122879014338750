import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserModel } from '../models/user';
import { AuthSelectors } from '../modules/auth/store/auth.selectors';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  apiURL = environment.apiURL;

  constructor(private httpClient: HttpClient, private store: Store) { }

  getCookie(name) {
      let cookieValue = null;
      if (document.cookie && document.cookie !== '') {
          const cookies = document.cookie.split(';');
          for (let i = 0; i < cookies.length; i++) {
              const cookie = cookies[i].trim();
              // Does this cookie string begin with the name we want?
              if (cookie.substring(0, name.length + 1) === (name + '=')) {
                  cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                  break;
              }
          }
      }
      return cookieValue;
  }

  getUser(): Observable<UserModel> {
    console.log('hei hie', this.store.selectSnapshot(AuthSelectors.token))
    return this.httpClient.get<UserModel>(`${this.apiURL}/identity/profile/`, {
      headers: {
          Authorization: 'Bearer ' + this.store.selectSnapshot(AuthSelectors.token)
      }
    })
  }
}
