import {ActivityModel} from "./activity-model";
import {SeasonCategoryModel} from "./season-category-model";
import * as moment from 'moment';
import { Activity } from './activity';
import { ɵCodegenComponentFactoryResolver } from '@angular/core';


export class SeasonModel {
    id: string;
    name: string;
    activity: ActivityModel;
    startDate: string;
    registrationDate: string;
    endDate: string;
    seasonCategories: SeasonCategoryModel[];
    previousSeason: string;

    static isRegistrationOpen(season: SeasonModel): boolean {
        
        const ret = moment().isBetween(
            moment(season.registrationDate),
            moment(season.endDate)
        )
        console.log('is registartion dat eopen', season.name, season, ret)
        return ret
    }

    static isClosedRegistrationOpen(season: SeasonModel): boolean {
        const ret =  moment().isBetween(
            moment(season.startDate),
            moment(season.registrationDate)
        )
        console.log('is closed registartion open', season.name, season, ret)
        return ret
    }

    static isActive(season: SeasonModel): boolean {
        return moment().isBetween(
            moment(season.startDate),
            moment(season.endDate)
        )
    }

    static isPreviousSeason(season: SeasonModel): boolean {
        return moment().isAfter(
            moment(season.endDate)
        )
    }

    static hasCategoryById(categoryId: string): (SeasonModel) => boolean  {
        return (season: SeasonModel): boolean => season.seasonCategories.find(sc => sc.id === categoryId) !== undefined
    }

    static inActivities(activities: Activity[]): (SeasonModel) => boolean {
        return (season: SeasonModel): boolean => activities.find(a => a.id === season.activity.id) !== undefined
    }
}
