import {SeasonModel} from "../models/season-model";
import {SeasonActions} from "../actions/season.actions";
import {Action, Selector, State} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {SeasonService} from "../services/season.service";
import {map, tap} from "rxjs/operators";
import { Activity } from '../models/activity';
import { VehicleRegistrationState } from './vehicle-registration.state';
import { ActivityModel } from '../models/activity-model';

const flattened = arr => [].concat(...arr);

export class SeasonStateModel {
    entities: SeasonModel[];
}

@State<SeasonStateModel>({
    name: 'seasons',
    defaults: {
        entities: []
    }
})
@Injectable()
export class SeasonState {

    constructor(private seasonService: SeasonService) { }

    @Selector()
    static active(state: SeasonStateModel) {
        const tmp =  state.entities.filter(SeasonModel.isActive)
        return tmp;
    }

    @Selector()
    static all(state: SeasonStateModel) {
        return state.entities
    }

    @Selector()
    static openRegistration(state: SeasonStateModel) {
        const tmp =  state.entities.filter(SeasonModel.isRegistrationOpen)
        return tmp;
    }

    @Selector()
    static closedRegistration(state: SeasonStateModel) {
        const tmp =  state.entities.filter(SeasonModel.isClosedRegistrationOpen)
        return tmp;
    }

    @Selector([VehicleRegistrationState.activitiesByApproved, SeasonState.closedRegistration])
    static closedApprovedSeasonCategories(activities: ActivityModel[], seasons: SeasonModel[]) {
        console.log('activitiies', activities, 'season categories', seasons)
        //console.log('closed season categories', seasons, activities)
        return flattened(seasons

            .filter(s => activities.find(a => a.id === s.activity.id))
            .map(s => s.seasonCategories))
    }

    @Selector()
    static previous(state: SeasonStateModel) {
        const tmp =  state.entities.filter(SeasonModel.isPreviousSeason)
        return tmp;
    }

    @Selector()
    static byCategoryId(state: SeasonStateModel) {
        return (categoryId: string) => {
            const entities = state.entities.filter(SeasonModel.hasCategoryById(categoryId))
            return entities[0].seasonCategories.find(sc => sc.id === categoryId)
        }
    }

    @Selector()
    static activeByActivites(state: SeasonStateModel) {
        console.log('hei hiei', state)
        return (activities: Activity[]) => {
            console.log('hoho', activities)
            const active = SeasonState.active(state)
            return active.filter(SeasonModel.inActivities(activities))
        }
    }

    @Action(SeasonActions.Set)
    set({getState, patchState}, {payload}) {
        const state = getState();
        //  state.entries = payload;
        console.log('heei hei', state, payload)
        patchState({
            ...state,
            entries: payload
        })
    }

    @Action(SeasonActions.FetchAll)
    fetchAll({ getState, setState }) {
        return this.seasonService.list().pipe(
            map(response => ({
                ...response,
                seasonCategories: response.seasonCategories.map(sc => ({
                    ...sc,
                    category: response.categories.find(category => category.id == sc.category),
                    config: response.competitionNumberConfigs.find(config => config.id == sc.config)
                }))
            })),
            map(response => ({
                ...response,
                seasons: response.seasons.map(s => ({
                    ...s,
                    categories: s.categories.map(categoryId => response.seasonCategories.find(sc => sc.id === categoryId)),
                    activity: response.activities.find(a => a.id === s.activity)
                }))
            })),
            map(response => response.seasons.map(season => ({
                ...season,
                seasonCategories: season.categories.map(sc => ({
                    ...sc,
                    verboseName: `${season.activity.name} - ${sc.category.name} (${sc.config.name})`
                }))
            }))),
            tap(entities => {
                const state = getState();
                setState({
                    ...state,
                    entities
                })
            })
        )
    }


}
