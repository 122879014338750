import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
    production: true,
    env: 'prod',
    //apiURL: 'https://msf.nor1.se',
    apiURL: 'https://regadmin.nmfsport.com',
    stripePublicKey: 'pk_live_51HRwmiJ48uoMvvRMaVPgmBAbj979rH5CzB2PPJDDW2vjg5Z2IFHDqFOJn0KdlAyOxDgnYzEaaRsyB752BoRWPrvw00zuCtdaFa',
    logLevel: NgxLoggerLevel.WARN,
    serverLogLevel: NgxLoggerLevel.OFF,
    stripePublicTestKey: 'pk_test_51HRwmiJ48uoMvvRMKgWv8zFiaVUDDaxLASwbfHdy62xyDALUkK3LhCb6lzfvXgADFMzDbArvnPVQVDEhaFl5TwRK00LlBP562Y',
    stripePublicLiveKey: 'pk_live_51HRwmiJ48uoMvvRMaVPgmBAbj979rH5CzB2PPJDDW2vjg5Z2IFHDqFOJn0KdlAyOxDgnYzEaaRsyB752BoRWPrvw00zuCtdaFa',
    androidConfigUrl: 'https://8ca8961f-63b3-491e-ae38-001e53c64fa7.s3-eu-west-1.amazonaws.com/prod/config.android.json',
    iosConfigUrl: 'https://8ca8961f-63b3-491e-ae38-001e53c64fa7.s3-eu-west-1.amazonaws.com/prod/config.ios.json',
    consentStandard: 'kjp-kom-og-prv-lisens',
    useTestProduct: false
};
