import {Component, OnInit} from '@angular/core';
import {VehicleRegistrationModel} from '../../models/vehicle-registration';
import {VehicleRegistrationService} from '../../services/vehicle-registration.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {filter, map, switchMap, tap, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AbstractControl, FormBuilder, Validators} from '@angular/forms';
import {frameNumberValidator} from '../../utils/validators';
import {ActivityService} from '../../services/activity.service';
import {Activity} from '../../models/activity';
import {ManufacturerService} from '../../services/manufacturer.service';
import {Manufacturer} from '../../models/manufacturer';
import {TypeDesignation} from '../../models/type-designation';

import {ModalController, ToastController, AlertController} from '@ionic/angular';
import {VehicleRegistrationUpdateRequest} from '../../models/vehicle-registration-update-request';
import {LoaderService} from '../../services/loader.service';
import {PaymentModalComponent} from '../payment-modal/payment-modal.component';
import { Store } from '@ngxs/store';
import { VehicleRegistrationActions } from 'src/app/actions/vehicle-registration.actions';
import { loadStripe } from '@stripe/stripe-js';
import { environment } from '../../../environments/environment';
import { FeatureFlagsService } from 'src/app/services/feature-flags.service';

function condValidator(parentControl: AbstractControl) {

    return (control: AbstractControl) => {
        const val = frameNumberValidator(control);
        if (val !== null) {
            if (parentControl.value) {
                return null;
            } else {
                return val;
            }
        }

        return val;
    };
}

@Component({
    selector: 'app-vehicle-registration-detail-form',
    templateUrl: './vehicle-registration-detail-form.component.html',
    styleUrls: ['./vehicle-registration-detail-form.component.scss'],
})
export class VehicleRegistrationDetailFormComponent implements OnInit {

    vr$: Observable<VehicleRegistrationModel>;
    vrForm;
    activities$: Observable<Activity[]>;
    manufacturers$: Observable<Manufacturer[]>;
    typeDesignations$: Observable<TypeDesignation[]>;
    typeDesignations: TypeDesignation[] = [];
    yearModelOptions = [];
    showBlackPlates$;
    serverError;

    constructor(
        private vrService: VehicleRegistrationService,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private activityService: ActivityService,
        private manufacturerService: ManufacturerService,
        private toastCtrl: ToastController,
        private loaderService: LoaderService,
        private router: Router,
        private modalCtrl: ModalController,
        private store: Store,
        private alertController: AlertController,
        public featureFlagsService: FeatureFlagsService
    ) { }

    ngOnInit() {
        this.vrForm = this.fb.group({
            id: [],
            frameNumber: [{disabled: true, value: ''}],
            customFrameNumber: [],
            manufacturer: [{} as Manufacturer, [Validators.required]],
            typeDesignation: [{} as TypeDesignation, [Validators.required]],
            yearModel: ['', [Validators.required]],
            activities: [[] as Activity[], [Validators.required]],
            engineNumber: [],
            blackPlates: [false],
            description: [''],
            isReRegistration: [false],
            previousOwner: ['']
        });

        //          this.frameNumber.setValidators({validators: [condValidator(this.customFrameNumber), Validators.required], updateOn: 'blur'});

        this.isReRegistration.valueChanges.subscribe(value => {
            if (value === true) {
                this.previousOwner.setValidators(Validators.required)
            } else {
                this.previousOwner.clearValidators()
            }

            this.previousOwner.updateValueAndValidity()
        })

        this.typeDesignations$ = this.manufacturer.valueChanges.pipe(
            filter(x => x !== null),
            switchMap((val: Manufacturer) => this.manufacturerService.getTypeDesignationByManufacturer(val.id).pipe(
                map(response => response.results),
                tap(r => {
                    console.log(' the value I\'m supposed to show', r);
                    this.typeDesignations = r;
                }
                )
            )),
        );

        this.customFrameNumber.valueChanges.subscribe(val => {
            this.frameNumber.updateValueAndValidity();
        });
        this.manufacturers$ = this.manufacturerService.getManufacturers().pipe(
            map(response => response.results)
        );

        this.vr$ = this.route.paramMap.pipe(
            switchMap((params: ParamMap) => this.vrService.vehicleRegistration(params.get('id'))),
            tap(
                vr => {
                    this.vrForm.patchValue({
                        id: vr.id,
                        frameNumber: vr.frameNumber || vr.customData.frameNumber || '',
                        customFrameNumber: vr.customFrameNumber,
                        manufacturer: vr.manufacturer || null ,
                        typeDesignation: vr.typeDesignation,
                        yearModel: vr.yearModel || vr.customData.yearModel,
                        activities: vr.activities,
                        engineNumber: vr.engineNumber || vr.customData.engineNumber || '',
                        blackPlates: vr.licensePlateNumber || !!vr.customData.blackPlates,
                        description: vr.description,
                        isReRegistration: vr.isReRegistration || false,
                        previousOwner: vr.previousOwner || ''
                    });

                    console.log('HIE HEIEH EHIHIE', vr)
                    if (!vr.customFrameNumber) {
                        if (vr.manufacturer) {
                            this.manufacturer.disable();
                        }

                        if (vr.typeDesignation) {
                            this.typeDesignation.disable();
                        }

                        if (vr.yearModel) {
                            this.yearModel.disable();
                        }
                    }

                    if (vr.manufacturer) {
                        setTimeout(() => {
                            this.manufacturer.setValue(vr.manufacturer);
                        }, 500);
                    }

                    this.store.dispatch(new VehicleRegistrationActions.Update(vr))
                }
            )
        );


        this.activities$ = this.activityService.activities().pipe(
            map(response => response.results),
        );

        const tmp = [];
        for (let i = new Date().getFullYear(); i >= 1970; i--) {
            tmp.push(i);
        }
        this.yearModelOptions = tmp;

        this.showBlackPlates$ = this.activities.valueChanges.pipe(
            map((activities: Activity[]) => activities.filter((x: Activity) => x.customLicensePlate).length > 0)
        );
    }

    get frameNumber() {
        return this.vrForm.controls.frameNumber;
    }

    get customFrameNumber() {
        return this.vrForm.controls.customFrameNumber;
    }

    get manufacturer() {
        return this.vrForm.controls.manufacturer;
    }

    get typeDesignation() {
        return this.vrForm.controls.typeDesignation;
    }

    get activities() {
        return this.vrForm.controls.activities;
    }

    get isReRegistration() {
        return this.vrForm.controls.isReRegistration
    }

    get previousOwner() {
        return this.vrForm.controls.previousOwner
    }

    async onSave() {

        const formData = this.vrForm.getRawValue();
        const {id, manufacturer, typeDesignation, frameNumber, customFrameNumber, yearModel, blackPlates, engineNumber, activities, description, isReRegistration, previousOwner} = formData;
        const data = {
            id,
            manufacturer: manufacturer?.id,
            typeDesignation: typeDesignation?.id,
            frameNumber,
            customFrameNumber,
            yearModel,
            licensePlateNumber: blackPlates,
            engineNumber,
            description,
            activities: activities
                .map(x => x.id) || [],
            isReRegistration,
            previousOwner
        } as VehicleRegistrationUpdateRequest;
        this.serverError = null;

        this.store.dispatch(new VehicleRegistrationActions.Update(formData as VehicleRegistrationModel))

        const loader = await this.loaderService.presentLoader('Lagrer dine endringer');
        this.vrService.updateVehicleRegistration(id, data)
            .pipe(
                //finalize(() => loader.dismiss())
            )
            .subscribe(() => {
                this.handleRemove(data, loader)
            }, async (err) => {
                this.onError(err)
                await loader.dismiss()
            });
    }

    onError(err) {
        this.serverError = err;
    }

    async onSubmit() {

        if (!this.vrForm.valid) {
            this.presentToast('Noen felter mangler eller ugyldige.');
            return;
        }

        const loader = await this.loaderService.presentLoader('Sender inn skjema');

        const formData = this.vrForm.getRawValue();
        const {id, manufacturer, typeDesignation, frameNumber, customFrameNumber, yearModel, blackPlates, engineNumber, activities, description, isReRegistration, previousOwner} = formData;
        const data = {
            id,
            manufacturer: manufacturer?.id,
            typeDesignation: typeDesignation?.id,
            frameNumber,
            customFrameNumber,
            yearModel,
            licensePlateNumber: blackPlates,
            engineNumber,
            description,
            activities: activities
                .map(x => x.id),
            isReRegistration,
            previousOwner
        } as VehicleRegistrationUpdateRequest;

        this.serverError = null;

        this.store.dispatch(new VehicleRegistrationActions.Update(formData as VehicleRegistrationModel))

        this.vrService.putVehicleRegistration(id, data)
            .pipe(
                tap(vr => {
                    this.store.dispatch(new VehicleRegistrationActions.Update({state: vr.state, id: vr.id} as VehicleRegistrationModel))
                }),
                //finalize(() => {
                //    loader.dismiss()
                //})
            )
            .subscribe((data) => {
                this.handleRemove(data, loader)
                
                //await this.presentPayment();
                
            }, async (err) => {
                this.onError(err)
                await loader.dismiss()
            });
    }

    async handleRemove(vr: any, loader) {
        
        if (!this.featureFlagsService.enableVehicleRegistrationPayment) {
            await loader.dismiss() 
            if (vr.state === 'A') {
                await this.router.navigate(['folder/vognkort']);
            } else {
                await this.router.navigate(['../'], { relativeTo: this.route });
            }

            return
        }
        loader.message = 'Sender deg til betaling...'

        try {
            loader.dismiss()
            
            const data = await this.vrService.initPurchase({
                'vehicle_registrations': [vr.id]
            }).pipe(take(1)).toPromise()

            const stripePromise = loadStripe(environment.stripePublicKey);
            const stripe = await stripePromise;

            const result = await stripe.redirectToCheckout({
                sessionId: data.id,
            });

            await loader.dismiss()
            
            if (result.error) {
                
            }
        } catch {
            await loader.dismiss()
            const alert = await this.alertController.create({
                header: 'Kunne ikke sende deg videre til betaling',
                message: 'Vi jobber med å rette saken.',
                buttons: [{
                    text: 'OK',
                    handler: () => {
                        this.router.navigate(['../'], { relativeTo: this.route });
                    }
                }]
            })
            await alert.present()
        }
    }

    onFocus(ev) {
        ev.target.parentNode.parentNode.parentNode.parentNode.parentNode.classList.add('item-has-focus');
    }

    onBlur(ev) {
        ev.target.parentNode.parentNode.parentNode.parentNode.parentNode.classList.remove('item-has-focus');
    }

    async presentToast(message: string) {
        const toast = await this.toastCtrl.create({
            message,
            position: 'top',
            cssClass: 'toast-color-pink',
            buttons: [
                {
                    text: 'Close',
                    role: 'cancel',
                    handler: () => {
                        console.log('Cancel clicked');
                    }
                }
            ]
        });

        toast.present();
    }

    get yearModel() {
        return this.vrForm.controls.yearModel;
    }

    async presentPayment() {
        const modal = await this.modalCtrl.create({
            component: PaymentModalComponent
        });

        await modal.present();

        await modal.onDidDismiss();
    }
}
